import React, { memo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import HeroImage from "../common/heroImage"

const UpperContent = memo(() => {
  const { heroImageContent } = useStaticQuery(query)
  const smImage = heroImageContent.media[0]

  return (
    <div className="w-full z-n-1">
      <HeroImage defaultImage={smImage}>
        <div className="h-40" />
      </HeroImage>
    </div>
  )
})

export default memo(UpperContent)

export const query = graphql`
  query UpperContentQuery {
    heroImageContent: contentfulContent(readableId: { eq: "bg-image-clear" }) {
      media {
        fluid(quality: 100, maxWidth: 3840) {
          ...GatsbyContentfulFluid_withWebp
        }
        title
      }
    }
  }
`
