import React from "react"
import { graphql } from "gatsby"
import ReactPlayer from "react-player"
import Page from "../components/common/page"
import PageContent from "../components/common/pageContent"
import Markdown from "../components/common/markdown"

const WhyAda = ({ data: { content } }) => {
  const {
    title,
    description,
    configuration: { videoUrl },
    references,
  } = content

  const markdown = references[0].markdown

  return (
    <Page title={title} description={description}>
      <PageContent>
        <div className="flex flex-col items-center w-full px-4">
          <div className="py-10 text-center px-2">
            <h1>
              <Markdown markdown={markdown} />
            </h1>
          </div>
          <div className="shadow-3xl pt-4 pb-10 md:px-12 sm:px-8 px-3 rounded-lg  max-w-6xl w-full">
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url={videoUrl}
                controls={true}
                pip={true}
                stopOnUnmount={false}
                playing={true}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </PageContent>
    </Page>
  )
}

export default WhyAda

export const query = graphql`
  query WhyAdaQuery {
    content: contentfulPage(readableId: { eq: "why-ada" }) {
      title
      description
      references {
        markdown {
          childMarkdownRemark {
            html
          }
        }
      }
      configuration {
        videoUrl
      }
    }
  }
`
