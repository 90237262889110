import React, { MouseEventHandler } from "react"
import classNames from "classnames"

const SkButton = (props: Props) => {
  const {
    children,
    style = "primary",
    onClick,
    type = "submit",
    disabled = false,
  } = props

  const className = classNames(
    "hover:opacity-100 opacity-90 py-1 px-4 rounded-md whitespace-nowrap",
    {
      "bg-primary-400 py-1 px-4 rounded-md whitespace-nowrap":
        type === "button",
      "bg-primary-400 text-white": style === "primary",
      "cursor-not-allowed bg-gray-500": disabled,
    }
  )

  return (
    <button
      className={className}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

type Props = {
  children: React.ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined
  style?: "primary" | "secondary"
  type?: "submit" | "reset" | "button"
  disabled?: boolean
}

export default SkButton
