import React, { memo } from "react"
import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import classNames from "classnames"

const SkLink = (props: Props) => {
  const {
    children,
    to,
    type,
    wrap,
    animate,
    style = "primary",
    size = "medium",
    newtab
  } = props

  const className = classNames("hover:opacity-100 opacity-90", {
    "text-wrap--curly": wrap === "curly",
    "text-wrap--quotes": wrap === "quotes",
    "text-wrap--square": wrap === "square",
    "bg-primary-400 py-1 px-4 rounded-md whitespace-nowrap": type === "button",
    "bg-primary-400 text-white": type === "button" && style === "primary",
    "text-base": size === "medium",
  })

  const Component = animate ? AniLink : Link

  const _props = {
    className,
    to,
    ...(animate && { swipe: true, direction: "left" }),
  }

  return to.startsWith("http") ? (
    (newtab ? (<a href={to} className={className} target="_blank"> {children} </a>) : <a href={to} className={className}> {children} </a>)
  ) : (
    <Component {..._props}>{children}</Component>
  )
}

type Props = {
  children: React.ReactNode
  to: string
  type?: "link" | "button"
  style?: "primary" | "secondary"
  wrap?: "curly" | "quotes" | "square"
  size?: "small" | "medium" | "large"
  animate?: boolean
  newtab?: boolean
}

export default memo(SkLink)
