import React from "react"
import "./src/styles/main.scss"
import "./src/styles/base.scss"

import "firebase/auth"
import "firebase/firestore"
//import "firebase/functions"

import { QuestionnaireProvider } from "./src/contexts/questionnaire/questionnaireContext"
import { ApplicationProvider } from "./src/contexts/app/appContext"

export const wrapRootElement = ({ element }) => {
  return (
    <ApplicationProvider>
      <QuestionnaireProvider>{element}</QuestionnaireProvider>
    </ApplicationProvider>
  )
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state)
    location.state.referrer = prevLocation ? prevLocation.pathname : null
}
