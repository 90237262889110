import React, { memo, useEffect, useState } from "react"
import { useMediaQuery } from "react-responsive"
import SkBackgroundImage from "./skBackgroundImage"

const HeroImage = ({
  defaultImage,
  lgImage,
  mdImage,
  smImage,
  xsImage,
  breakpoints,
  children = [],
}: Props) => {
  const [image, setImage] = useState(defaultImage)
  const { lg = 1024, md = 768, sm = 640, xs = 576 } = breakpoints || {}

  const isXs = useMediaQuery({ maxWidth: xs - 1 })
  const isSm = useMediaQuery({ maxWidth: sm - 1, minWidth: xs })
  const isMd = useMediaQuery({ maxWidth: md - 1, minWidth: sm })
  const isLg = useMediaQuery({ maxWidth: lg - 1, minWidth: md })

  useEffect(() => {
    isXs && xsImage && setImage(xsImage)
    isSm && smImage && setImage(smImage)
    isMd && mdImage && setImage(mdImage)
    isLg && lgImage && setImage(lgImage)
  }, [isXs, isSm, isMd, isLg])

  return (
    <div className="max-w-4k mx-auto">
      <SkBackgroundImage image={image} style={style}>
        <div>{children}</div>
      </SkBackgroundImage>
    </div>
  )
}

export default memo(HeroImage)

const style = {
  marginBottom: "-4rem",
  paddingBottom: "4rem",
}

interface Props {
  defaultImage?: any
  lgImage?: any
  mdImage?: any
  smImage?: any
  xsImage?: any
  breakpoints?: any
  children?: React.ReactNode
}
