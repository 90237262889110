import React from "react"
import BackgroundImage from "gatsby-background-image"

const SkBackgroundImage = ({ image, children, style = {} }) => {
  return (
    <BackgroundImage fluid={image?.fluid} fadeIn style={style}>
      {children}
    </BackgroundImage>
  )
}

export default SkBackgroundImage
