import firebase from "gatsby-plugin-firebase"

export const logout = async (remove = false) => {
  const user = firebase.auth().currentUser
  if (!user) return

  if (remove) {
    await user.delete()
  } else {
    await firebase.auth().signOut()
  }
}
