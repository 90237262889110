import { useState } from "react"

const lsMock = {
  getItem: () => {},
  setItem: () => {},
}

//@ts-ignore
const _localStorage = typeof window !== "undefined" ? window.localStorage : lsMock

// Hook
function useLocalStorage(key: string, initialValue: any) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = _localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      _localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }

  return [storedValue, setValue]
}

const getItem = (key: string, initialValue?: any) => {
  try {
    // Get from local storage by key
    const item = _localStorage.getItem(key)
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : initialValue
  } catch (error) {
    // If error also return initialValue
    console.log(error)
    return initialValue
  }
}

const setItem = (key: string, value: any) => {
  try {
    // Save to local storage
    _localStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    // A more advanced implementation would handle the error case
    console.log(error)
  }
}

export const localStorage = {
  useLocalStorage,
  getItem,
  setItem,
}
