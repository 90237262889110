import classNames from "classnames"
import React, { memo, useEffect, useState } from "react"
import { userApi } from "../../api"
import { useApplicationContext } from "../../contexts/app/appContext"
import { useQuestionnaireContext } from "../../contexts/questionnaire/questionnaireContext"

const SignUpConfirm = memo(() => {
  const { resetResponses } = useQuestionnaireContext()
  const { authUser } = useApplicationContext()

  const [resendEnabled, setResendEnabled] = useState(true)

  const resendHandler = () => {
    if (resendEnabled && authUser?.uid) {
      userApi
        .resendRegistrationEmail(authUser.uid)
        .then(res => {
          if (res.status === 200 && res.data === "OK") {
            setResendEnabled(false)
          }
        })
        .catch(error => console.log(error))
    }
  }

  useEffect(() => {
    let timeout
    if (!resendEnabled) {
      timeout = setTimeout(() => {
        setResendEnabled(true)
      }, 10000)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [resendEnabled])

  const className = classNames("", {
    "cursor-pointer underline text-blue-600 ": resendEnabled,
  })

  useEffect(() => {
    return () => {
      resetResponses()
    }
  }, [])

  return (
    <div className="max-w-2xl mx-auto pt-12 px-4">
      <div className="shadow-3xl text-center py-10 md:px-12 sm:px-8 px-3 rounded-lg w-full">
        <p className="md:px-20 px-4 select-none">
          One final step and you can start coding like Ada. If all went well you
          would have received an email to verify your email, if not click{" "}
          <span className={className} onClick={resendHandler}>
            here
          </span>{" "}
          to resend, or look in you spam, even the best emails get lost
          sometimes.
        </p>
      </div>
    </div>
  )
})

export default SignUpConfirm
