import styled from "styled-components"
import MenuRound from "../../assets/menu.svg"
import MenuSquare from '../../assets/menu-square.svg'
import LogoIcon from '../../assets/logo.svg'
import LogoWrappedIcon from '../../assets/logoWrappedIcon.svg'



export const MenuIconRound = styled(MenuRound)`
  height: 2rem;
`
export const MenuIconSquare = styled(MenuSquare)`
  height: 2rem;
  width: 2rem;
`
export const Logo = styled(LogoIcon)`
  height: 100%;
  max-height: 2rem;
  width: auto;
`
export const LogoWrapped = styled(LogoWrappedIcon)`
  height: 100%;
  width: auto;
`