export class QuestionnaireGraph {
  private questions: { [key: string]: Question }
  private entryQuestion: Question

  constructor(entryQuestionId) {
    this.questions = {}
    this.entryQuestion = this.addQuestion(entryQuestionId, true)
  }

  addRelation(
    sourceQuestionId: string,
    answerId: string,
    destinationQuestionId?: string | undefined
  ) {
    const source = this.addQuestion(sourceQuestionId)
    let destination
    if (destinationQuestionId) {
      destination = this.addQuestion(destinationQuestionId)
      source.addNextQuestion(answerId, destination)
      destination.addPreviousQuestion(answerId, source)
    }
  }

  addQuestion(id: string, isEntry = false): Question {
    let question = this.questions[id]
    if (!question) {
      question = new Question(id, isEntry)

      this.questions[id] = question
    }
    return question
  }

  getQuestion(id: string): Question | null {
    return this.questions[id]
  }

  getEntryQuestion() {
    return this.entryQuestion
  }
}

export class Question {
  id: any
  nextQuestions: { [key: string]: string }
  previousQuestions: { [key: string]: string }
  isEntryQuestion: boolean
  isFinal: boolean = true

  constructor(id: string, isEntry = false) {
    this.id = id
    this.isEntryQuestion = isEntry
    this.nextQuestions = {}
    this.previousQuestions = {}
  }

  addNextQuestion(answerId: string, nextQuestion: Question): void {
    this.nextQuestions[answerId] = nextQuestion.id
    this.isFinal = false
  }

  addPreviousQuestion(answerId: string, previousQuestion: Question): void {
    this.previousQuestions[answerId] = previousQuestion.id
  }
}
