/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import DefaultLayout from './defaultLayout'
import EmptyLayout from './emptyLayout'
import PageLayout from "./pageLayout"


const Layout = ({ children, pageContext, location }: Props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const Layout = LayoutsDictionary[pageContext.layout || LayoutTypes.Default]

  return (
    <Layout location={location}>
      <div>
        <main>{children}</main>
      </div>
    </Layout>
  )
}

interface Props {
  children: React.ReactNode
  pageContext: any
  location: any
}


export enum LayoutTypes {
  Default,
  Empty,
  Page
}

const LayoutsDictionary = {
  [LayoutTypes.Default]: DefaultLayout,
  [LayoutTypes.Empty]: EmptyLayout,
  [LayoutTypes.Page]: PageLayout
}


export default Layout
