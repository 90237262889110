import React, { memo, useEffect, useState } from "react"
import { useApplicationContext } from "../../contexts/app/appContext"
import {
  registerWithEmail,
  RegistrationErrors,
  signUp,
} from "../../services/authentication"
import {
  InputField,
  InputTypes,
  SkForm,
  FormError,
  signUpValidationSchema,
  FiltersTypes,
  providerSignUpValidationSchema,
} from "../common/form"
import SkButton from "../common/skButton"
import { SignUpMethods } from "./signUpMethodSelect"

const SignUpForm = ({ method }: Props) => {
  const { authUser, setUser } = useApplicationContext()
  const [init, setInit] = useState(false)
  const [initialValues, setInitialValues] = useState({
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  })

  const isProviderSignUp = method !== SignUpMethods.Email

  useEffect(() => {
    if (init || !authUser) return
    const { displayName, email } = authUser
    setInitialValues({
      username: "",
      firstName: displayName?.split(" ")[0] || "",
      lastName: displayName?.substr(displayName.indexOf(" ") + 1) || "",
      email: email || "",
      password: "",
    })
    setInit(true)
  }, [init, authUser])

  const submitHandler = async (values, actions) => {
    const { setStatus } = actions
    try {
      setStatus(null)

      let _authUser
      if (method === SignUpMethods.Email) {
        _authUser = await registerWithEmail(values)
      } else {
        _authUser = authUser
      }
      if (!_authUser) throw new Error()

      const user = await signUp(values, _authUser)
      setUser(user)
      window.location.hash = "#success"
    } catch (error) {
      let status: any = { error: "Registration failed. " }
      switch (error.message) {
        case RegistrationErrors.internalError:
          status.error = "An error occurred."
          status.info = "Please try again."
          break
        default:
          status.info = `If you have an account please <a href="${process.env.GATSBY_SWOTKNOT_FRONT_URL}/signIn">log-in</a>.`
          break
      }
      console.log(error.message)
      setStatus(status)
    }
  }
  return (
    <SkForm
      initialValues={initialValues}
      validation={
        isProviderSignUp
          ? providerSignUpValidationSchema
          : signUpValidationSchema
      }
      onSubmit={submitHandler}
      enableReinitialize={true}
      formClassName="lg:w-3/5 w-full max-w-4xl mx-auto"
    >
      {props => {
        const { isValid, dirty, isSubmitting, status } = props
        const submitDisabled = !(isValid && dirty) || isSubmitting

        return (
          <>
            <div className="flex flex-1 flex-col items-center">
              <div className="pt-10 text-xl text-center px-2">About you</div>
            </div>
            <div className="flex flex-wrap justify-center py-10 lg:px-8 md:px-32 xs:px-8 px-4">
              <div className="flex flex-col shadow-3xl py-10 md:px-12 sm:px-8 px-3 rounded-lg w-full">
                <InputField
                  type={InputTypes.Text}
                  name="username"
                  label="Username"
                  placeholder="Username"
                  filter={FiltersTypes.Alphanumeric}
                  containerClass={containerClass}
                  errorClass={errorClass}
                />
                <InputField
                  type={InputTypes.Text}
                  name="firstName"
                  label="First name"
                  placeholder="First name"
                  filter={FiltersTypes.Alphabetic}
                  containerClass={containerClass}
                  errorClass={errorClass}
                />
                <InputField
                  type={InputTypes.Text}
                  name="lastName"
                  label="Last name"
                  placeholder="First name"
                  filter={FiltersTypes.Alphabetic}
                  containerClass={containerClass}
                  errorClass={errorClass}
                />
                <InputField
                  type={InputTypes.Email}
                  name="email"
                  label="Email"
                  placeholder="Email"
                  containerClass={containerClass}
                  errorClass={errorClass}
                  disabled={isProviderSignUp}
                  filter={FiltersTypes.Email}
                />
                {!isProviderSignUp && (
                  <InputField
                    type={InputTypes.Password}
                    name="password"
                    label="Password"
                    placeholder="Password"
                    containerClass={containerClass}
                    errorClass={errorClass}
                  />
                )}
                {status?.error && (
                  <div className="mx-auto">
                    <FormError error={status.error} info={status.info} />
                  </div>
                )}
              </div>
              <div className="flex justify-between w-full px-10 pt-8 max-w-lg">
                <SkButton type="button" onClick={() => window.location.hash = "#select-method"}>
                  previous
                </SkButton>

                <SkButton disabled={submitDisabled}>submit</SkButton>
              </div>
            </div>
          </>
        )
      }}
    </SkForm>
  )
}

const containerClass = "flex self-end justify-center py-2 w-full"
const errorClass = ""

interface Props {
  method: SignUpMethods
}

export default SignUpForm
