import firebase from "gatsby-plugin-firebase"
import { AccountTypes } from "../api"

export const create = async (user: User): Promise<User> => {
  const userRef = firebase.firestore().doc(`users/${user.uid}`)
  const userSnapshot = await userRef.get()

  if (userSnapshot.exists) {
    throw new Error(UserServiceError.userExists)
  }
  await userRef.set({ ...user })
  return user
}

export const save = async (user: User): Promise<User> => {
  await firebase
    .firestore()
    .doc(`users/${user.uid}`)
    .set({ ...user })
  return user
}

export const get = async (uid: string): Promise<object> => {
  try {
    const userDocument = await firebase.firestore().doc(`users/${uid}`).get()
    return {
      uid,
      ...userDocument.data(),
    }
  } catch (error) {
    console.error("Error fetching user", error)
    throw error
  }
}

export const getByEmail = async (
  email: string | null
): Promise<object | null> => {
  if (!email) return null
  try {
    const userDocs = await firebase
      .firestore()
      .collection("users")
      .where("email", "==", email.trim().toLocaleLowerCase())
      .limit(1)
      .get()

    const user = (userDocs.docs[0] as unknown) as User

    return user
  } catch (error) {
    console.error("Error fetching user", error)
    throw error
  }
}

export interface User {
  name: string
  email: string
  uid: string
  accountType: AccountTypes
  photoURL: string
  phoneNumber: string
  displayName: string
  password: string
  username: string
  firstName: string
  lastName: string
}

export enum UserServiceError {
  userExists = "UserExists",
}
