import React, { memo } from "react"
import Header from "../components/homepage/header"
import UpperContent from "../components/layout/upperContent"

const PageLayout = ({ children, location }) => {
  return (
    <>
      <Header location={location} />
      <UpperContent />
      {children}
    </>
  )
}
export default PageLayout
