import { Field } from "formik"
import React, { memo } from "react"
import AnswerCard from "./answerCard"

const AnswerField = ({ element: e, id, onSelect, selected }) => {
  return (
    <Field key={e.id} type="radio" name={id} value={e.answerId}>
      {() => {
        return (
          <AnswerCard
            id={id}
            text={e.text}
            image={e.image}
            imageAltText={e.image}
            onClick={() => onSelect(id, e.answerId)}
            selected={selected}
          />
        )
      }}
    </Field>
  )
}

export default memo(AnswerField)
