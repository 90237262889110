import { Question } from "./graph/questionnaireGraph"
import {
  QuestionnaireInstance as questionnaire,
  QuestionnaireInstance,
} from "./graph/questionnaireGraphFactory"

export const getFlowQuestions = (
  userAnswers: { [key: string]: string } = {},
  target: string = ""
): FlowQuestionsResponse => {
  let node = questionnaire.getEntryQuestion()
  let currentQuestion = node
  let previousQuestion

  if (Object.keys(userAnswers).length === 0) {
    return { currentQuestion, previousQuestion }
  }

  while (true) {
    if (node.id === target) {
      return { currentQuestion, previousQuestion }
    }

    const answerId = userAnswers[node.id]
    const nextQuestionId = node.nextQuestions[answerId]
    if (!nextQuestionId) {
      break
    }
    previousQuestion = node
    currentQuestion = questionnaire.getQuestion(nextQuestionId)!
    node = currentQuestion
  }

  return { currentQuestion, previousQuestion }
}

export const getEntryQuestion = () => QuestionnaireInstance.getEntryQuestion()

interface FlowQuestionsResponse {
  currentQuestion: Question
  previousQuestion: Question | undefined
}
