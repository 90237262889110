import React from "react"
import { graphql } from "gatsby"
import Page from "../components/common/page"
import PageContent from "../components/common/pageContent"
import HeroImage from "../components/common/heroImage"
import SkImage from "../components/common/skImage"
import Markdown from "../components/common/markdown"
import StartLink from "../components/homepage/startLink"
import classNames from "classnames"

const IndexPage = ({ data: { content } }) => {
  const {
    configuration: {
      images: imagesIndex,
      firstSectionIcons: firstSectionIconsIndex,
    },
    references,
  } = content

  const images = references[imagesIndex].media
  const smImage = images[0]
  const xsImage = images[1]

  const firstSectionIcons = references[firstSectionIconsIndex].references

  const props = {
    defaultImage: smImage,
    //  smImage,
    // xsImage,
  }

  return (
    <Page title="Home">
      <div className="w-full z-n-1">
        <HeroImage {...props}>
          <div className="lg:min-h-30vw md:min-h-50vw min-h-90vw" />
        </HeroImage>
      </div>
      <PageContent>
        <div>
          <div className="pb-10">
            <h1 className="text-font-primary">
              <div className="flex justify-center pb-4 lg:text-3xl text-2xl text-center px-4 lg:pt-0 pt-6">
                See which coding language is the one for you?
              </div>
              <div className="flex justify-center">
                <StartLink />
              </div>
            </h1>
          </div>
          <div className="flex w-full flex-wrap justify-center">
            {firstSectionIcons.map((iconContent, i) => {
              const icon = iconContent.media[0]

              const className = classNames(
                "p-4 xl:w-1/5 lg:w-2/6 xs:w-1/2 w-full px-8 2xl:px-12 max-w-xs",
                {
                  "xl:-mt-14": i === 0 || i === 4,
                  "xl:-mt-4": i === 1 || i === 3,
                  "lg:-mt-8": i === 0 || i === 2,
                  "xl:mt-0": i === 2,
                }
              )

              return (
                <div key={iconContent.id} className={className}>
                  <div className="pb-4 px-14 pt-0">
                    <SkImage
                      image={icon}
                      className="inline-svg-h-20 inline-svg-mx-auto"
                    />
                  </div>
                  <div className="text-md px-4 lg:text-left text-center">
                    <h3>
                      <Markdown markdown={iconContent.markdown} />
                    </h3>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </PageContent>
    </Page>
  )
}

export const query = graphql`
  query HomepageQuery {
    content: contentfulPage(readableId: { eq: "homepage-v1" }) {
      id
      name
      title
      references {
        media {
          fluid(quality: 100, maxWidth: 3840) {
            ...GatsbyContentfulFluid_withWebp
          }
          title
        }
        references {
          id
          media {
            svg {
              content
            }
            gatsbyImageData(
              formats: WEBP
              jpegProgressive: false
              placeholder: NONE
            )
            title
          }
          markdown {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      configuration {
        images
        firstSectionIcons
      }
    }
  }
`

// const containerStyle = {
//   zIndex: -1,
// }

export default IndexPage
