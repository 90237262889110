import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const SkImage = ({ image, alt, ...props }: Props) => {
  const { gatsbyImageData, svg, title = alt } = image

  let _image

  if (gatsbyImageData) {
    _image = getImage(image)
    return <GatsbyImage image={_image!} alt={title || "ada image"} {...props} />
  } else if (svg) {
    _image = svg.content
    return <div dangerouslySetInnerHTML={{ __html: _image }} {...props} />
  } else {
    return null
  }
}

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  image: any
  alt?: string
}

export default SkImage
