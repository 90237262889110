import React from "react"
import { useQuestionnaireContext } from "../../contexts/questionnaire/questionnaireContext"
import { RouteNames } from "../../utils/enums"
import SkLink from "../common/skLink"

const StartLink = () => {
  const { pendingQuestion } = useQuestionnaireContext()

  const to = pendingQuestion.isFinal
    ? RouteNames.SignUp
    : `${RouteNames.Question}/${pendingQuestion.id}`

  return (
    <SkLink to={to} type="button">
      {pendingQuestion.isEntryQuestion ? "start here" : "resume"}
    </SkLink>
  )
}

export default StartLink
