import { QuestionnaireGraph } from "./questionnaireGraph"

const questionnaire = new QuestionnaireGraph("programming-languages-knowledge")

// Screen 1
questionnaire.addRelation(
  "programming-languages-knowledge",
  "yes",
  "how-many-languages"
)
questionnaire.addRelation(
  "programming-languages-knowledge",
  "no",
  "why-programming"
)

// Screen 2
questionnaire.addRelation("how-many-languages", "1", "why-start-coding")
questionnaire.addRelation("how-many-languages", "2", "why-start-coding")
questionnaire.addRelation("how-many-languages", "all", "why-start-coding")

// Screen 3
questionnaire.addRelation(
  "why-programming",
  "sounds-interesting",
  "why-start-coding"
)
questionnaire.addRelation(
  "why-programming",
  "job-opportunities",
  "why-start-coding"
)
questionnaire.addRelation(
  "why-programming",
  "everybody-is-doing-it",
  "why-start-coding"
)
questionnaire.addRelation(
  "why-programming",
  "my-mum-said-so",
  "why-start-coding"
)

// Screen 4
questionnaire.addRelation("why-start-coding", "for-fun", "fun-kind")
questionnaire.addRelation("why-start-coding", "skill-seeker", "skill-seek-goal")
questionnaire.addRelation("why-start-coding", "game-guru", "game-language")
questionnaire.addRelation("why-start-coding", "jovial-job", "job-field")
questionnaire.addRelation(
  "why-start-coding",
  "open-opportunities",
  "opportunities-fields"
)

// Screen 5
questionnaire.addRelation("fun-kind", "mobile", "fun-mobile")
questionnaire.addRelation("fun-kind", "web", "fun-web")
questionnaire.addRelation("fun-kind", "game", "fun-game")
questionnaire.addRelation("fun-kind", "ai", "fun-ai")

// Screen 6
questionnaire.addRelation("fun-mobile", "swift-for-apple")
questionnaire.addRelation("fun-mobile", "kotlin-for-android")
questionnaire.addRelation("fun-mobile", "csharp-for-windows")
questionnaire.addRelation("fun-mobile", "javascript-for-all-platforms")

// Screen 7
questionnaire.addRelation("fun-web", "design-web-pages", "fun-web-design")
questionnaire.addRelation(
  "fun-web",
  "make-applications",
  "fun-web-applications"
)

// Screen 8
questionnaire.addRelation("fun-web-design", "javascript")
questionnaire.addRelation("fun-web-design", "html")

// Screen 9
questionnaire.addRelation("fun-web-applications", "javascript")
questionnaire.addRelation("fun-web-applications", "python")

// Screen 10
questionnaire.addRelation("fun-game", "javascript-mobile")
questionnaire.addRelation("fun-game", "csharp-for-pc")
questionnaire.addRelation("fun-game", "javascript-web-games")

// Screen 11
questionnaire.addRelation("fun-ai", "python")
questionnaire.addRelation("fun-ai", "r")

// Screen 12
questionnaire.addRelation("skill-seek-goal", "mobile-coding", "skill-mobile")
questionnaire.addRelation("skill-seek-goal", "web-development", "skill-web")
questionnaire.addRelation("skill-seek-goal", "data-analysis", "skill-data")

//Screen 13
questionnaire.addRelation("skill-mobile", "swift-for-apple")
questionnaire.addRelation("skill-mobile", "kotlin-for-android")
questionnaire.addRelation("skill-mobile", "csharp-for-windows")
questionnaire.addRelation("skill-mobile", "javascript-for-all-platforms")

// Screen 14
questionnaire.addRelation("skill-web", "design-web-pages", "skill-web-pages")
questionnaire.addRelation(
  "skill-web",
  "make-applications",
  "skill-web-applications"
)
questionnaire.addRelation("skill-web", "project-management", "skill-web-pm")

// Screen 15
questionnaire.addRelation("skill-web-pages", "javascript")
questionnaire.addRelation("skill-web-pages", "html")

// Screen 16
questionnaire.addRelation("skill-web-applications", "javascript")
questionnaire.addRelation("skill-web-applications", "python")

// Screen 17
questionnaire.addRelation("skill-web-pm", "javascript")
questionnaire.addRelation("skill-web-pm", "python")

// Screen 18
questionnaire.addRelation("skill-data", "r-for-machine-learning")
questionnaire.addRelation("skill-data", "python-for-ai")
questionnaire.addRelation("skill-data", "r-for-ai")

// Screen 19
questionnaire.addRelation("job-field", "web-development", "job-web")
questionnaire.addRelation("job-field", "desktop-software", "job-desktop")
questionnaire.addRelation("job-field", "data-science", "job-data")
questionnaire.addRelation("job-field", "mobile-coding", "job-mobile")

// Screen 20
questionnaire.addRelation("job-web", "small-business", "job-web-small-business")
questionnaire.addRelation("job-web", "startup", "job-web-startup")
questionnaire.addRelation("job-web", "enterprise", "job-web-enterprise")

// Screen 21
questionnaire.addRelation("job-desktop", "csharp-for-windows")
questionnaire.addRelation("job-desktop", "swift-for-mac")
questionnaire.addRelation("job-desktop", "java-for-cross-platform")
questionnaire.addRelation("job-desktop", "javascript-for-task-automation")
questionnaire.addRelation("job-desktop", "python-for-advanced-automation")

// Screen 22
questionnaire.addRelation("job-data", "r-for-statistics")
questionnaire.addRelation("job-data", "python-for-analysis")

// Screen 23
questionnaire.addRelation("job-mobile", "swift-for-apple")
questionnaire.addRelation("job-mobile", "kotlin-for-android")
questionnaire.addRelation("job-mobile", "csharp-for-windows")
questionnaire.addRelation("job-mobile", "javascript-for-all-platforms")

// Screen 24
questionnaire.addRelation(
  "game-language",
  "virtual-reality",
  "game-virtual-reality"
)
questionnaire.addRelation("game-language", "mobile-games", "game-mobile")
questionnaire.addRelation("game-language", "web-games", "game-web")
questionnaire.addRelation("game-language", "pc-games", "game-pc")
questionnaire.addRelation(
  "game-language",
  "graphics-for-games",
  "game-graphics"
)
questionnaire.addRelation("game-language", "console-games", "game-consoles")

// Screen 25
questionnaire.addRelation(
  "opportunities-fields",
  "mobile-coding",
  "opportunities-mobile"
)
questionnaire.addRelation(
  "opportunities-fields",
  "web-development",
  "opportunities-web"
)
questionnaire.addRelation(
  "opportunities-fields",
  "gaming",
  "opportunities-game"
)
questionnaire.addRelation("opportunities-fields", "data", "opportunities-data")

// Screen 26
questionnaire.addRelation("opportunities-mobile", "swift-for-apple")
questionnaire.addRelation("opportunities-mobile", "kotlin-for-android")
questionnaire.addRelation("opportunities-mobile", "csharp-for-windows")
questionnaire.addRelation(
  "opportunities-mobile",
  "javascript-for-all-platforms"
)

// Screen 27
questionnaire.addRelation("opportunities-web", "javascript-to-design-pages")
questionnaire.addRelation("opportunities-web", "html-for-web-design")
questionnaire.addRelation("opportunities-web", "javascript-for-application")
questionnaire.addRelation("opportunities-web", "python-for-applications")
questionnaire.addRelation("opportunities-web", "php-for-ecommerce")
questionnaire.addRelation("opportunities-web", "java-for-aas")

// Screen 28
questionnaire.addRelation("opportunities-game", "csharp-for-virtual-reality")
questionnaire.addRelation("opportunities-game", "2d-mobile-games")
questionnaire.addRelation("opportunities-game", "3d-mobile-games")
questionnaire.addRelation("opportunities-game", "javascript-for-web-games")
questionnaire.addRelation("opportunities-game", "csharp-for-pc-games")

// Screen 29
questionnaire.addRelation("opportunities-data", "r-for-machine-learning")
questionnaire.addRelation("opportunities-data", "python-for-ai")

// Screen 38 - Game - console
questionnaire.addRelation("game-consoles", "cplus-for-consoles")
questionnaire.addRelation("game-consoles", "csharp-for-consoles")

// Screen 37 - Game - graphics
questionnaire.addRelation("game-graphics", "cplusplus-for-game-graphics")

// Screen 36 - Game - pc
questionnaire.addRelation("game-pc", "csharp-for-graphics")
questionnaire.addRelation("game-pc", "csharp-for-full-stack-games")

//  Screen 35 - Game - Web
questionnaire.addRelation("game-web", "javascript-web-games")

// Screen 34 - Game - mobile
questionnaire.addRelation("game-mobile", "cplus-for-2d")
questionnaire.addRelation("game-mobile", "csharp-for-3d")

//  Screen 33 - Game - virtual-reality
questionnaire.addRelation("game-virtual-reality", "csharp-for-virtual-reality")

//  Screen 32 - Job - Web - Enterprise
questionnaire.addRelation("job-web-enterprise", "java-for-the-enterprise")
questionnaire.addRelation("job-web-enterprise", "csharp-for-the-enterprise")

//  Screen 31 - Job - web - startup
questionnaire.addRelation("job-web-startup", "python-for-startups")
questionnaire.addRelation("job-web-startup", "javascript-for-startups")

//  Screen 30 - Job - web - small business
questionnaire.addRelation("job-web-small-business", "php-for-small-businesses")

export { questionnaire as QuestionnaireInstance }
