import React, { memo } from "react"
import Header from "../components/homepage/header"

const DefaultLayout = ({ children, location }) => {
  return (
    <>
      <Header location={location} />
      {children}
    </>
  )
}
export default DefaultLayout
