import React from "react"
import CurveMask from "../../assets/curveMask.svg"

const PageContent = ({ children }) => {
  return (
    <div>
      <CurveMask
        fill="#fff"
        className="svg-shadow  absolute"
        style={{ width: "100vw", fill: "#fff" }}
      />
      <div className="flex lg:pt-20 sm:pt-12 pt-6 lg:px-12 pb-12">
        <div className="w-full z-10">{children}</div>
      </div>
    </div>
  )
}

export default PageContent
