import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react"
import firebase from "gatsby-plugin-firebase"
import { logout } from "../../services/authentication"

const defaultState = {
  authUser: undefined,
  user: undefined,
  setAuthUser: () => {},
  setUser: () => {},
  signOut: () => {},
}

const ApplicationContext = createContext<ContextType>(defaultState)

const reducer = (state, action: IAction) => {
  const { payload, type } = action
  switch (type) {
    case ApplicationActionTypes.SET_AUTH_USER: {
      return {
        ...state,
        authUser: payload,
      }
    }
    case ApplicationActionTypes.SET_USER: {
      return {
        ...state,
        user: payload,
      }
    }
    default:
      return state
  }
}

export const ApplicationProvider = ({
  initialState = defaultState,
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const setAuthUser = useCallback(
    (user: firebase.User) => {
      if (!user) return
      dispatch({ type: ApplicationActionTypes.SET_AUTH_USER, payload: user })
    },
    [dispatch]
  )

  const signOut = useCallback(async () => {
    try {
      await logout()
      dispatch({
        type: ApplicationActionTypes.SET_AUTH_USER,
        payload: undefined,
      })
    } catch (error) {
      console.log(error)
    }
  }, [dispatch])

  const setUser = useCallback(
    user => {
      if (!user) return
      dispatch({ type: ApplicationActionTypes.SET_USER, payload: user })
    },
    [dispatch]
  )

  const contextValue = useMemo(
    () => ({
      ...state,
      setAuthUser,
      setUser,
      signOut,
      dispatch,
    }),
    [state]
  )

  return (
    <ApplicationContext.Provider value={contextValue}>
      {children}
    </ApplicationContext.Provider>
  )
}

export const useApplicationContext = () => useContext(ApplicationContext)

interface IAction {
  payload: any
  type: ApplicationActionTypes
}

type ContextType = {
  authUser: firebase.User | undefined
  user: any | undefined
  setAuthUser: (value: firebase.User) => void
  setUser: (value: any) => void
  signOut: () => void
}

export enum ApplicationActionTypes {
  SET_AUTH_USER,
  SET_USER,
}
