import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react"
import { Question, getFlowQuestions } from "../../services/questionnaire"
import { localStorage } from "../../utils"

const responsesLsKey = "questionnaireResponses"
const responses = localStorage.getItem(responsesLsKey, {})

const defaultState = {
  responses,
  pendingQuestion: getFlowQuestions(responses).currentQuestion,
  setResponse: () => {},
  resetResponses: () => {}
}

const QuestionnaireContext = createContext<ContextType>(defaultState)

const reducer = (state, action: IAction) => {
  const { payload, type } = action
  switch (type) {
    case QuestionnaireActionTypes.SET_RESPONSE: {
      const { key, value } = payload
      const responses = { ...state.responses }
      responses[key] = value
      localStorage.setItem(responsesLsKey, responses)
      const { currentQuestion } = getFlowQuestions(responses)
      return {
        ...state,
        responses,
        pendingQuestion: currentQuestion,
      }
    }
    case QuestionnaireActionTypes.CLEAR_RESPONSES: {
      localStorage.setItem(responsesLsKey, {})
      return {
        ...state,
        responses: {},
        pendingQuestion: getFlowQuestions({}).currentQuestion
      }
    }
    default:
      return state
  }
}

export const QuestionnaireProvider = ({
  initialState = defaultState,
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const setResponse = useCallback(
    (payload: { key: string; value: any }) => {
      dispatch({ type: QuestionnaireActionTypes.SET_RESPONSE, payload })
    },
    [dispatch]
  )

  const resetResponses = useCallback(() => {
    dispatch({ type: QuestionnaireActionTypes.CLEAR_RESPONSES })
  }, [dispatch])

  const contextValue = useMemo(
    () => ({
      ...state,
      setResponse,
      resetResponses,
      dispatch,
    }),
    [state]
  )

  return (
    <QuestionnaireContext.Provider value={contextValue}>
      {children}
    </QuestionnaireContext.Provider>
  )
}

export const useQuestionnaireContext = () => useContext(QuestionnaireContext)

interface IAction {
  payload?: any
  type: QuestionnaireActionTypes
}

type ContextType = {
  responses: { [key: string]: string }
  pendingQuestion: Question
  setResponse: (value: { key: string; value: any }) => void
  resetResponses: () => void
}

export enum QuestionnaireActionTypes {
  SET_RESPONSE,
  CLEAR_RESPONSES,
}
