import { graphql, navigate, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import { useApplicationContext } from "../../contexts/app/appContext"
import { useQuestionnaireContext } from "../../contexts/questionnaire/questionnaireContext"
import {
  auth,
  registerWithProvider,
  RegistrationErrors,
} from "../../services/authentication"
import { RouteNames } from "../../utils"
import SkButton from "../common/skButton"
import AnswerCard from "../questions/answerCard"

const SignUpMethodSelect = ({ onSelect }) => {
  const { facebookLogo, googleLogo, emailIcon } = useStaticQuery(query)
  const [method, setMethod] = useState<SignUpMethods>()
  const [error, setError] = useState("")
  const { pendingQuestion } = useQuestionnaireContext()

  const { signOut, setAuthUser } = useApplicationContext()

  const register = (provider, method: SignUpMethods) => {
    registerWithProvider(provider)
      .then(user => {
        setAuthUser(user)
        onSelect(method)
      })
      .catch(error => {
        console.log(error)
        setError(error.message)
        signOut()
      })
  }

  const submitHandler = () => {
    signOut()

    console.log(method)

    if (method === SignUpMethods.Google) {
      register(new auth.GoogleAuthProvider(), method)
      return
    }
    if (method === SignUpMethods.Facebook) {
      register(new auth.FacebookAuthProvider(), method)
      return
    }
    if (method === SignUpMethods.Email) {
      onSelect(method)
      return
    }
  }

  const previousClickHandler = () => {
    return navigate(`${RouteNames.Question}/${pendingQuestion.id}`)
  }

  return (
    <>
      <div className="flex flex-1 flex-col items-center">
        <div className="pt-10 text-xl text-center px-2">
          One final step and your road to coding will start.
          <br />
        </div>
      </div>

      <div className="flex flex-wrap justify-center py-10 px-8">
        <AnswerCard
          id={SignUpMethods.Email}
          text={"SignUp Now"}
          image={emailIcon.childImageSharp}
          imageAltText={emailIcon.name}
          onClick={() => window.location = `${process.env.GATSBY_SWOTKNOT_FRONT_URL}/on-boarding`}
        />
      </div>


      {/* <div className="flex flex-wrap justify-center py-10 px-8">
        <AnswerCard
          id={SignUpMethods.Facebook}
          text={"Facebook"}
          image={facebookLogo.childImageSharp}
          imageAltText={facebookLogo.name}
          onClick={() => setMethod(SignUpMethods.Facebook)}
          selected={method === SignUpMethods.Facebook}
        />
        <AnswerCard
          id={SignUpMethods.Google}
          text={"Google"}
          image={googleLogo.childImageSharp}
          imageAltText={googleLogo.name}
          onClick={() => setMethod(SignUpMethods.Google)}
          selected={method === SignUpMethods.Google}
        />
        <AnswerCard
          id={SignUpMethods.Email}
          text={"Email"}
          image={emailIcon.childImageSharp}
          imageAltText={emailIcon.name}
          onClick={() => setMethod(SignUpMethods.Email)}
          selected={method === SignUpMethods.Email}
        />
      </div> */}
      {error && (
        <div className="flex justify-between w-full px-10 max-w-lg mx-auto pb-4">
          {error === RegistrationErrors.authenticateUserFailed && (
            <p>Authentication failed. If you have an account please log-in.</p>
          )}
        </div>
      )}
      <div className="flex justify-between w-full px-10 max-w-lg mx-auto">
        <SkButton type="button" onClick={previousClickHandler}>
          previous
        </SkButton>

        <SkButton onClick={submitHandler} disabled={!method}>
          next
        </SkButton>
      </div>
    </>
  )
}

export default SignUpMethodSelect

export enum SignUpMethods {
  Facebook = "facebook",
  Google = "google",
  Email = "email",
}

export const query = graphql`
  query SignUpMethodQuery {
    facebookLogo: file(name: { eq: "facebook" }) {
      id
      childImageSharp {
        gatsbyImageData(formats: WEBP, quality: 50, placeholder: NONE)
      }
      name
    }
    googleLogo: file(name: { eq: "google" }) {
      id
      childImageSharp {
        gatsbyImageData(formats: WEBP, quality: 50, placeholder: NONE)
      }
      name
    }
    emailIcon: file(name: { eq: "email" }) {
      id
      childImageSharp {
        gatsbyImageData(formats: WEBP, quality: 50, placeholder: NONE)
      }
      name
    }       
  }
`
