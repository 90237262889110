import React from "react"
import { graphql } from "gatsby"
import Page from "../components/common/page"
import PageContent from "../components/common/pageContent"
import Markdown from "../components/common/markdown"
import SkImage from "../components/common/skImage"

const About = ({ data: { content } }) => {
  const { title, description, references } = content
  return (
    <Page title={title} description={description}>
      <PageContent>
        <div className="flex flex-wrap justify-center py-6 px-8">
          {references.map(card => {
            const { markdown, media, name } = card
            return (
              <div className="flex flex-col sm:mx-8">
                <h2 className="text-center py-8">{name}</h2>
                <div className="shadow-3xl pt-4 pb-10 md:px-12 sm:px-8 px-3 rounded-lg w-full max-w-xs h-full">
                  <div className="flex justify-center pb-2">
                    <SkImage image={media[0]} />
                  </div>
                  <h3>
                    <Markdown markdown={markdown} />
                  </h3>
                </div>
              </div>
            )
          })}
        </div>
      </PageContent>
    </Page>
  )
}

export default About

export const query = graphql`
  query AboutUsQuery {
    content: contentfulPage(readableId: { eq: "about-us" }) {
      title
      description
      references {
        readableId
        name
        markdown {
          childMarkdownRemark {
            html
          }
        }
        media {
          gatsbyImageData(
            formats: WEBP
            jpegProgressive: false
            placeholder: NONE
          )
          title
        }
      }
    }
  }
`
