import { swotKnotClient } from "./http"

const userApi = {
  saveAccount: (data: accountProps) =>
    swotKnotClient
      .post("/action/ada/save-account", data)
      .then(res => res)
      .catch(error => {
        throw error
      }),
  resendRegistrationEmail: (id: string): Promise<any> =>
    swotKnotClient
      .get(`/action/${id}/verification/email/resend`)
      .then(res => res)
      .catch(error => {
        throw error
      }),
}

interface accountProps {
  username: string
  firstName: string
  lastName: string
  email: string
  firebaseId: string
  accountType: AccountTypes
  password: string
}

export enum AccountTypes {
  guestTutor = "GuestTutor",
  guestStudent = "GuestStudent",
}

export { userApi }
