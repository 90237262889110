import firebase from "gatsby-plugin-firebase"
import { AccountTypes, userApi } from "../../api"
import {
  create as createUser,
  getByEmail as getUserByEmail,
  UserServiceError,
} from "../userService"

export const auth = firebase.auth

export const registerWithEmail = async (
  props: ISignUpProps
): Promise<firebase.User> => {
  const email = props.email.trim().toLocaleLowerCase()
  let firebaseUser: firebase.User | null

  try {
    const { user } = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, props.password)
    firebaseUser = user
  } catch (error) {
    throw new Error(RegistrationErrors.authenticateUserFailed)
  }

  const existingUser = await getUserByEmail(email)
  if (!firebaseUser || existingUser) {
    throw new Error(RegistrationErrors.authenticateUserFailed)
  }

  return firebaseUser
}

export const registerWithProvider = async (
  provider
): Promise<firebase.User> => {
  try {
    const result = await firebase.auth().signInWithPopup(provider)
    const credential = result.credential

    //@ts-ignore This gives you a Google Access Token. You can use it to access the Google API.
    const token = credential!.accessToken
    // The signed-in user info.

    if (!result.user) {
      throw new Error(RegistrationErrors.authenticateUserFailed)
    }

    const user: firebase.User = result.user

    const { email } = result.user
    const existingUser = await getUserByEmail(email)
    if (existingUser) {
      throw new Error(RegistrationErrors.authenticateUserFailed)
    }

    return user
  } catch (error) {
    throw error
  }
}

export const signUp = async (
  formValues: ISignUpProps,
  firebaseUser: firebase.User
) => {
  const { firstName, lastName, email: _email } = formValues
  const email = _email.trim().toLocaleLowerCase()
  const name = `${firstName} ${lastName}`

  try {
    await userApi.saveAccount({
      ...formValues,
      email,
      firebaseId: firebaseUser.uid,
      accountType: AccountTypes.guestStudent,
    })
  } catch (error) {
    console.log(error.stack)
    throw new Error(RegistrationErrors.internalError)
  }  

  const user = {
    ...formValues,
    name,
    email,
    uid: firebaseUser.uid,
    accountType: AccountTypes.guestStudent,
    photoURL: firebaseUser.photoURL || "",
    phoneNumber: firebaseUser.phoneNumber || "",
    displayName: firebaseUser.displayName || "",
    password: "",
  }

  try {
    await createUser(user)
    return user
  } catch (error) {
    switch (error.message) {
      case UserServiceError.userExists:
        throw new Error(RegistrationErrors.createUserFailed)
      default:
        throw new Error(RegistrationErrors.internalError)
    }
  }
}

interface ISignUpProps {
  username: string
  firstName: string
  lastName: string
  email: string
  password: string
}

export enum RegistrationErrors {
  internalError = "internalError",
  authenticateUserFailed = "authenticateUserFailed",
  saveAccountFailed = "saveAccountFailed",
  createUserFailed = "createUserFailed",
}
