import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import Page from "../components/common/page"
import PageContent from "../components/common/pageContent"
import SignUpConfirm from "../components/signUp/signUpConfirm"
import SignUpForm from "../components/signUp/signUpForm"
import SignUpMethodSelect, {
  SignUpMethods,
} from "../components/signUp/signUpMethodSelect"
import { useQuestionnaireContext } from "../contexts/questionnaire/questionnaireContext"
import { isBuild, RouteNames } from "../utils"

const SignUp = ({ location }) => {
  const [method, setMethod] = useState(SignUpMethods.Email)
  const { pendingQuestion, responses } = useQuestionnaireContext()

  if (
    !isBuild &&
    (!pendingQuestion.isFinal ||
      (pendingQuestion.isFinal && !responses[pendingQuestion.id]))
  ) {
    navigate(`${RouteNames.Question}/${pendingQuestion.id}`)
    return null
  }

  const onMethodSelection = async (method: SignUpMethods) => {
    setMethod(method)
    window.location.hash = "#about-you"
  }

  return (
    <Page title="Sign-up">
      <PageContent>
        {(location.hash === "#select-method" || !location.hash) && (
          <SignUpMethodSelect onSelect={onMethodSelection} />
        )}
        {location.hash === "#about-you" && <SignUpForm method={method} />}
        {location.hash === "#success" && <SignUpConfirm />}
      </PageContent>
    </Page>
  )
}

export default SignUp
