import React, { useEffect } from "react"
import { graphql, navigate } from "gatsby"
import Page from "../components/common/page"
import Markdown from "../components/common/markdown"
import PageContent from "../components/common/pageContent"
import { questionValidator, SkForm } from "../components/common/form"
import { useQuestionnaireContext } from "../contexts/questionnaire/questionnaireContext"
import SkButton from "../components/common/skButton"
import { getFlowQuestions } from "../services/questionnaire"
import { RouteNames } from "../utils/enums"
import AnswerField from "../components/questions/answerField"

const QuestionPage = ({ data: { content } }) => {
  const { title: titleMarkdown, answers, slug: id } = content
  const { responses } = useQuestionnaireContext()
  const { currentQuestion, previousQuestion } = getFlowQuestions(responses, id)

  useEffect(() => {
    if (currentQuestion.id !== id) {
      navigate(`/question/${currentQuestion.id}`)
    }
  }, [])

  const { setResponse } = useQuestionnaireContext()

  const submitHandler = values => {
    const answerId = values[id]
    setResponse({ key: id, value: answerId })

    if (currentQuestion.isFinal) {
      return navigate(RouteNames.SignUp)
    }

    const nextQuestionId = currentQuestion.nextQuestions[answerId]
    return navigate(`${RouteNames.Question}/${nextQuestionId}`)
  }

  const previousHandler = () => {
    return navigate(`${RouteNames.Question}/${previousQuestion!.id}`)
  }

  const validation = questionValidator(id)

  return (
    <Page title="Home">
      <PageContent>
        <SkForm
          initialValues={{
            [id]: responses[id],
          }}
          validation={validation}
          validateOnMount={true}
          onSubmit={submitHandler}
        >
          {props => {
            const { setFieldValue, values, isValid, isSubmitting } = props
            const submitDisabled = !isValid || isSubmitting
            return (
              <div className="flex flex-1 flex-col items-center">
                <Markdown
                  className="pt-10 text-xl text-center px-2"
                  markdown={titleMarkdown}
                />
                <div className="flex flex-wrap justify-center py-10 px-8">
                  {answers.map(e => (
                    <AnswerField
                      key={e.id}
                      element={e}
                      id={id}
                      onSelect={setFieldValue}
                      selected={values[id] === e.answerId}
                    />
                  ))}
                </div>
                <div className="flex justify-between w-full px-10 max-w-lg">
                  {!currentQuestion.isEntryQuestion ? (
                    <SkButton type="button" onClick={previousHandler}>
                      previous
                    </SkButton>
                  ) : (
                    <div />
                  )}
                  <SkButton disabled={submitDisabled}>next</SkButton>
                </div>
              </div>
            )
          }}
        </SkForm>
      </PageContent>
    </Page>
  )
}

export default QuestionPage

export const query = graphql`
  query QuestionPageQuery($id: String!) {
    content: contentfulQuestion(id: { eq: $id }) {
      slug
      name
      title {
        childMarkdownRemark {
          html
        }
      }
      answers {
        answerId
        image {
          gatsbyImageData(
            formats: WEBP
            jpegProgressive: false
            quality: 50
            placeholder: NONE
          )
          title
        }
        name
        id
        text {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
