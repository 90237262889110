import React from "react"

const Markdown = ({ markdown, ...props }: Props) => {
  return markdown?.childMarkdownRemark ? (
    <div
      {...props}
      dangerouslySetInnerHTML={{ __html: markdown?.childMarkdownRemark?.html }}
    />
  ) : (
    <div {...props}>{markdown}</div>
  )
}

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  markdown: { childMarkdownRemark: { html: any } }
}

export default Markdown
