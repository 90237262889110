import React from "react"
import SkLink from "../common/skLink"
import Sidebar from "react-sidebar"
import classNames from "classnames"
import { useState } from "react"
import { Logo, LogoWrapped, MenuIconSquare } from "../common/icons"
import { RouteNames } from "../../utils"
import { getEntryQuestion } from "../../services/questionnaire"
import { useQuestionnaireContext } from "../../contexts/questionnaire/questionnaireContext"
import { navigate } from "gatsby"
import SkButton from "../common/skButton"

const Header = ({ location }) => {
  const [open, setOpen] = useState(false)
  const { responses, resetResponses, pendingQuestion } = useQuestionnaireContext()

  const rootClassName = classNames("", {
    "w-0": !open,
  })

  const pathname: string = location?.pathname
  const hideStartLink =
    pathname.startsWith(RouteNames.Question) ||
    pathname.startsWith(RouteNames.SignUp)

  const entryQuestion = getEntryQuestion()

  const hasStartedQuestionnaire = Object.keys(responses).length > 0 && pendingQuestion

  const onStartClickHandler = () => {
    navigate(`${RouteNames.Question}/${entryQuestion.id}`)
    resetResponses()
  }

  return (
    <>
      <Sidebar
        sidebar={<SideBarContent setOpen={setOpen} />}
        rootClassName={rootClassName}
        open={open}
        onSetOpen={setOpen}
        contentClassName="h-screen"
        overlayClassName="h-screen"
        styles={styles.sidebarStyles}
      >
        {[]}
      </Sidebar>
      <div className="xs:px-14 px-6 xs:mt-14 mt-8 mx-auto w-full absolute z-1">
        <div className="flex w-full bg-white shadow-xl rounded-md py-5 justify-between px-4">
          <div className="flex  w-2/6">
            <div
              className="flex h-full items-center text-primary-400 cursor-pointer md:hidden"
              onClick={() => setOpen(true)}
            >
              <MenuIconSquare />
            </div>
            <div className="px-6 xs:px-2">
              <SkLink to="/">
                <span className="xs:inline hidden">
                  <Logo />
                </span>
                <span className="xs:hidden">
                  <LogoWrapped />
                </span>
              </SkLink>
            </div>
          </div>
          <div className="md:flex hidden justify-center items-center w-4/6 pr-4">
            {links.map((link, i) => {
              return (
                <React.Fragment key={link.to}>
                  {(link.text == "codle" ? (<SkLink to={link.to} newtab={true} >{link.text}</SkLink>) : (<SkLink to={link.to}>{link.text}</SkLink>))}
                  {!(hideStartLink && i === links.length - 1) && (
                    <span className="px-4">|</span>
                  )}
                </React.Fragment>
              )
            })}
          </div>
          {!hideStartLink && (
            <SkButton
              onClick={onStartClickHandler}
              type="button"
              style="primary"
            >
              {hasStartedQuestionnaire ? "retake quiz" : "start here"}
            </SkButton>
          )}
        </div>
      </div>
    </>
  )
}

const SideBarContent = ({ setOpen }) => {
  return (
    <div className="p-4 pl-0">
      {links.map(link => (
        <div
          className="flex items-center px-4 py-2"
          key={link.to}
          onClick={() => setOpen(false)}
        >
          {link && <SkLink to={link.to}>{link.text}</SkLink>}
        </div>
      ))}
    </div>
  )
}

export default Header

const links = [
  {
    text: "why Ada?",
    to: "/why-ada",
    wrap: "quotes",
  },
  {
    text: "about us",
    to: "/about-us",
    wrap: "quotes",
  },
  {
    text: "codle",
    to: `${process.env.GATSBY_CODLE}`,
    wrap: "quotes",
  },
  {
    text: "login",
    to: `${process.env.GATSBY_SWOTKNOT_FRONT_URL}/signIn`,
    wrap: "quotes",
  },
]

const styles = {
  sidebarStyles: {
    sidebar: { background: "white" },
    root: { overflow: "unset", height: "100%", zIndex: "9999" },
  },
}
